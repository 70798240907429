<template>

    <div class="container-fluid" style="height:calc(100vh);" v-if="loaded">

        <div class="row h-100">

            <div class="col-md-auto ps-5 pe-4 bg-light" style="border-right: 1px solid lightgray;">
                
                <div class="sticky-top pt-4">
                    <h4 class="mb-3">Administration</h4>
                    
                    <div class="list-group list-group-flush">
                        <router-link :to="{name: 'Admin2.dashboard'}" exact-active-class="active" active-class="active" class="list-group-item list-group-item-action list-group-item-light"><i class="bi bi-clipboard-check me-2"></i>Tableau de bord</router-link>
                        <router-link :to="{name: 'Admin2.users'}" active-class="active" class="list-group-item list-group-item-action list-group-item-light"><i class="bi bi-people me-2"></i>Utilisateurs</router-link>
                        <router-link :to="{name: 'Admin2.groups'}" active-class="active" class="list-group-item list-group-item-action list-group-item-light"><i class="bi bi-collection me-2"></i>Groupes</router-link>
                        <router-link :to="{name: 'Admin2.infras'}" active-class="active" class="list-group-item list-group-item-action list-group-item-light"><i class="bi bi-display me-2"></i>Infrastructures</router-link>
                        <router-link :to="{name: 'Admin2.quotas'}" active-class="active" class="list-group-item list-group-item-action list-group-item-light"><i class="bi bi-clipboard-data me-2"></i>Profils des Quotas</router-link>
                        <router-link :to="{name: 'Admin2.templates'}" active-class="active" class="list-group-item list-group-item-action list-group-item-light"><i class="bi bi-box-seam me-2"></i>Templates</router-link>
                        <!-- <router-link :to="{name: 'Admin2.events'}" active-class="active" class="list-group-item list-group-item-action list-group-item-light"><i class="bi bi-envelope me-2"></i>Evènements</router-link> -->
                        <router-link v-if="userInfo.authLevel === 0" :to="{name: 'Admin2.settings'}" active-class="active" class="list-group-item list-group-item-action list-group-item-light"><i class="bi bi-gear me-2"></i>Configuration</router-link>
                        <router-link :to="{name: 'Admin2.about'}" active-class="active" class="list-group-item list-group-item-action list-group-item-light"><i class="bi bi-info-circle me-2"></i>A propos de ...</router-link>
                        <router-link :to="{name: 'Dashboard.datacenters'}" class="list-group-item list-group-item-action list-group-item-light" active-class="active" aria-current="true"><i class="bi bi-box-arrow-in-left me-2"></i>Retour espace utilisateur</router-link>
                    </div>

                </div>

            </div>
            
            <div class="col-xl-6 col-sm py-4 ps-5 pe-5 bg-light">
                <router-view :user-info="userInfo" />
            </div>

            <div class="col bg-light">
                
            </div>

        </div>

    </div>
    <div class="container-fluid" style="height:calc(100vh);" v-else>
        <div class="row h-100 align-items-center">
            <div class="col bg-light text-center" style="border-right: 1px solid lightgray;">
                <span class="spinner spinner-border"></span>
                <br>
                Chargement en cours
            </div>
        </div>
    </div>

</template>

<style scoped>

    .container-fluid {
        background-color: #f8f9fa !important;
    }

    .list-group-item-light {
        background-color: #f8f9fa;
    }
    .list-group-item-light.list-group-item-action:hover {
        background-color: #ebebeb;
    }
    .list-group-flush > .list-group-item {
        border: none;
    }
    .list-group-item-light.list-group-item-action.active {
        background-color: #E5E5E5;
        color: #636464;
        border-left: 2px solid lightskyblue;
    }

</style>

<script setup>

    import {defineProps, defineEmits, ref, onMounted} from "vue";
    import { useRouter } from "vue-router";
    import axios from "axios";

    let props = defineProps(["userInfos"]);
    let emits = defineEmits(["reload"]);

    let userInfo = ref(null);
    let error = ref(null);
    let loaded = ref(false);

    onMounted( () => {

        axios.get("/api/users/me")
            .then( (answer) => {
                userInfo.value = answer.data.meResponse;
                loaded.value = true;
            })
            .catch( (e) => {
                error.value = (e.response) ? e.response.data.message : e; 
            });
    })


</script>